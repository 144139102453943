@import '../../../less/variable.less';

.button {
  margin-bottom: 16px;
}

.button-add {
  background-color: @secondary-color !important;
  color: @white-color !important;
  border: none !important;
}

.title {
  margin: 16px 0 0;
}

.add-promotion .ant-modal-footer {
  display: none;
}

.break-word {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;