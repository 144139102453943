@import '../../less/variable.less';

.transport-location-container {
  padding: 15px;

  .card-content {
    max-height: 45vh;
    overflow: auto;
  }

  .title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px;
  }

  .ant-table-content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.btn-action {
  position: absolute;
  right: 0;
  top: -98px;
  display: flex;
  min-width: 310px;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    border-radius: 8px;
    padding: 8px 20px;
    min-height: 40px;
    width: 150px;
    &:first-child,
    &:last-child {
      border-radius: 8px !important;
    }
    &:hover {
      border-color: transparent;
    }
    &.save {
      background-color: @secondary-color;
      color: @white-color;
    }
  }
}

.form-container {
  position: relative;
  max-width: 600px;
  margin-top: 20px !important;
  padding-bottom: 40px !important;

  .ant-form-item-label {
    min-width: 130px;
  }

  .form-confirm-btn {
    position: absolute;
    right: 0;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;